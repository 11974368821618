/**
* ./composables/useApi.ts
 *
 * Wrapper around useAsyncData API calls. It handles error and success messages and displays `toast` accordingly
 *
 */

export const useApi = async (handle: string, url: string, options: object = {}, silent: boolean = false) => {
    const { $apiFetch, $formatValidationErrors } = useNuxtApp();
    const config = useRuntimeConfig().public.nuxtSanctumAuth;
    const toasts = useToastsStore();

    const { data, error, pending, refresh } = await useAsyncData(handle, () => $apiFetch(url, options));

    if (data.value && data.value.message) {
        if(! silent) {
            toasts.success(data.value.message);
        }
    }

    if(error.value) {
        let statusCode = error.value.statusCode;
        if (statusCode === 422) {
            if(! silent) {
                toasts.validationError($formatValidationErrors(error.value.data.errors));
            }
            return;
        }

        if (statusCode === 403 || statusCode === 419) {
            if(! silent) {
                toasts.error(error.value.data.message);
            }
            await navigateTo(config.redirects.login);

            return
        }

        if(! silent) {
            toasts.error(error.value.data.message);
        }
    }

    return {
        data,
        error,
        pending,
        refresh
    }
};

